
($ => {
  // スムーススクロール
  $.fn.smooth = (function (settings) {
    function isHighOther() {
      let high = 0
      if ($('header').length) {
        if ($('header.c-header-fixed').length) {
          high += $('header').outerHeight()
        }

        // モーダル判定
        if (event) {
          // モーダル閉じる
          if ($(event.target).filter('[data-dismiss]').length) {
            return high
          } // モーダル内スクロール

          if ($(event.target).closest('.modal').length) {
            high -= $('header').outerHeight()

            return high
          }
        }
      }

      return high
    }

    // this.hash = window.location.hash
    this.settingsDef = {
      target: '',
      scrollSelecotor: 'html, body'
    }
    this.settings = $.extend(this.settingsDef, settings)
    // this.is_set_hash = function () {
    //   if (this.settings.target) {
    //     window.location.hash = '#smooth-' + this.settings.target
    //   }

    //   return false
    // }

    this.start = function () {
      const speed = 500
      const hightOhter = isHighOther()

      let $target = ''
      if (this.settings.target) {
        $target = $('[data-smooth="' + this.settings.target + '"]')
      }

      if ($target) {
        // $target.show()
        let position = 0
        if (this.settings.scrollSelecotor === 'html, body') {
          position = $target.offset().top - hightOhter - 10
        } else {
          const th = $target.position()
          const sh = $(this.settings.scrollSelecotor).scrollTop()
          position = th.top + sh + 1
        }

        // 位置取得不可
        if ($target.is(':hidden')) {
          return false
        }

        $(this.settings.scrollSelecotor).animate({ scrollTop: position }, speed, 'swing')
      }
    }

    this.is_hash_start = function () {
      const smooth_hash = window.location.hash
      const speed = 500
      const hightOhter = isHighOther()

      let $target = ''
      if (smooth_hash.match('#smooth-')) {
        $target = $('[data-smooth="' + smooth_hash.split('-')[1] + '"]')
      }

      if ($target) {
        // $target.show()
        let position = 0
        if (this.settings.scrollSelecotor === 'html, body') {
          position = $target.offset().top - hightOhter - 10
        } else {
          const th = $target.position()
          const sh = $(this.settings.scrollSelecotor).scrollTop()
          position = th.top + sh + 1
        }

        // 位置取得不可
        if ($target.is(':hidden')) {
          return false
        }

        $(this.settings.scrollSelecotor).animate({ scrollTop: position }, speed, 'swing')
      }
    }

    this.run = function () {
      // this.is_set_hash()
      switch (document.readyState) {
        case 'interactive':
          $(window).on('load', () => {
            this.is_hash_start()
          })
          break
        case 'complete':
          this.start()
          break
        default:
          this.start()
      }
    }

    return this
  })
})(jQuery)
